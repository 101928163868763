import './App.css';
import React from 'react';
import MainLayout from './Containers/MainLayout';
import { AuthContextProvider } from './Context/authContext';
import ThemeProvider from './Utils/themeProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './Utils/i18next/i18n.js';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { AppContextProvider } from './Context/appContext';
import { FormContextProvider } from './Context/formContext';
// import { Worker } from '@react-pdf-viewer/core';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

try {
  Sentry.init({
    dsn: 'https://7f79b22080c41471c45cd2ac4abc3514@o4506744673861632.ingest.sentry.io/4506744681529344',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/more\.flame\.edu\.in/],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} catch (error) {
  console.error('Sentry initialization error:', error);
}

function App() {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <FormContextProvider>
          <AuthContextProvider>
            <ThemeProvider>
              <I18nextProvider i18n={i18n}>
                <div className="App">
                  <MainLayout />
                </div>
              </I18nextProvider>
            </ThemeProvider>
          </AuthContextProvider>
        </FormContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
