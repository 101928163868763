import React, { useContext } from 'react';
import { AuthContext } from '../../Context/authContext';
import { Navigate, useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes';
import { ROLES, PERMISSIONS } from '../../Constants/constant';

function KenPrivateRoute({ id, children, redirectRoute = routes.home }) {
  const {
    state: { isAuthenticated, user },
    dispatch,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  // Auto Logout Maximum 6Hrs = 21600 seconds * 1000 units
  setTimeout(() => {
    dispatch({
      type: 'LOGOUT',
      payload: {
        user: {},
        token: null,
      },
    });
    navigate('/login');
  }, 21600 * 1000);

  if (!isAuthenticated) {
    return <Navigate to={routes.login} />;
  }

  const roleData = user?.role ? user?.role : 'APPLICANT';

  if (id && roleData && ROLES[roleData] && PERMISSIONS[roleData][id]) {
    return children;
  }
  return <Navigate to={redirectRoute} />;
}

export default KenPrivateRoute;
