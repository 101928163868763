import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: '16px',
  },
  submitBtn: {
    background: '#041e39 !important',
    color: 'white !important',
  },
  saveBtn: {
    color: '#041e39 !important',
    backgroundColor: 'transparent !important',
    border: '1px solid #041e39',
  },
}));

export default function KenDialog(props) {
  const {
    open,
    toggleOpen,
    handleClose,
    title,
    dialogText,
    negativeButtonText,
    positiveButtonText,
    negativeButtonProps,
    positiveButtonProps,
    dialogContent,
    dialogActions,
    children,
    titleContainerStyles,
    maxWidth,
    dialogActionProps,
    negativeButtonClick,
    positiveButtonClick,
    negativeBtnType,
    positiveButtonLabelProps,
    negativeButtonLabelProps,
    hidePositiveButton,
    hideNegativeButton,
    Component,
    innerProps,
    onClose,
    minWidth = '362px',
  } = props;

  const [onPositiveButtonClick, setOnPositiveButtonClick] = React.useState(() => positiveButtonClick);
  const [onNegativeButtonClick, setOnNegativeButtonClick] = React.useState(
    () =>
      negativeButtonClick ||
      (() => {
        toggleOpen(false);
      })
  );
  const [positiveButtonClickChange, setPositiveButtonClickChange] = React.useState(0);

  const compRef = React.useRef();

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" style={{ minWidth: minWidth }} fullWidth maxWidth={maxWidth}>
      <DialogTitle id="form-dialog-title" classes={{ root: classes.padding }} style={{ ...titleContainerStyles }}>
        {title}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {dialogContent || <>{dialogText && <DialogContent classes={{ root: classes.padding }}>{dialogText && <DialogContentText>{dialogText}</DialogContentText>}</DialogContent>}</>}
      <DialogContent classes={{ root: classes.padding }}>
        {children ? children : null}
        {Component && (
          <>
            <Component
              {...innerProps}
              setOnPositiveButtonClick={setOnPositiveButtonClick}
              setOnNegativeButtonClick={setOnNegativeButtonClick}
              // setIsOpen={setIsOpen}
              isOpen={open}
              toggleOpen={toggleOpen}
              ref={compRef}
              positiveButtonClickChange={positiveButtonClickChange}
            />
          </>
        )}
      </DialogContent>
      {dialogActions && (
        <DialogActions sx={{ gap: '20px' }} {...dialogActionProps}>
          {!hideNegativeButton && (
            <Button
              {...negativeButtonProps}
              className={classes.saveBtn}
              onClick={() => {
                console.log('KenDialog: Negative Button Clikc');
                onNegativeButtonClick();
              }}
              color="primary"
              variant={negativeBtnType ? negativeBtnType : 'text'}
            >
              <Typography {...negativeButtonLabelProps}>{negativeButtonText || 'Cancel'}</Typography>
            </Button>
          )}
          {!hidePositiveButton && (
            <Button
              style={{ margin: '15px 15px' }}
              className={classes.submitBtn}
              onClick={() => {
                positiveButtonClick();
                setPositiveButtonClickChange(positiveButtonClickChange + 1);
              }}
              color="primary"
              variant="contained"
            >
              <Typography {...positiveButtonLabelProps}>{positiveButtonText || 'Ok'}</Typography>
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
