import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme, useMediaQuery } from '@mui/material';
import KenButton from '../../Components/KenButton';
import { TABLET_BREAKPOINT } from '../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.card.backgroundColor,
    mixBlendMode: 'normal',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '12px',
    border: 'none',
    height: '100%',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      boxShadow: 'unset',
    },
  },
  amount: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '100%',
    'text-align': 'right',
    'text-transform': 'capitalize',
    color: theme.palette.KenColors.neutral900,
  },
  applicationName: {
    color: theme.palette.KenColors.grey2,
    fontWeight: 500,
  },
  applicationNumber: {
    color: theme.palette.KenColors.grey2,
    fontWeight: '500 !important',
    fontSize: '14px',
    marginTop: '12px',
  },
  due: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      'text-align': 'right',
      'text-transform': 'capitalize',
      color: theme.palette.KenColors.statusRed,
    },
  },
  btn: {
    width: '100%',
    maxWidth: theme.spacing(22.5),
    padding: '20px !important',
    lineHeight: '20px !important',
  },
  mobilBtn: {
    'border-radius': '50% !important',
    height: '48px !important',
    width: '48px !important',
    padding: '0 !important',
    'min-width': '48px',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    '& > span:first-child': {
      margin: 'auto',
    },
  },
  bottomBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
}));

export function MobilePaymentCard(props) {
  const theme = useTheme();
  const classes = useStyles();
  console.log(props, 'j6589uy586');
  return (
    <React.Fragment>
      <CardContent>
        <Grid container justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
          <Grid container item direction="row" justifyContent="space-between">
            <Grid item xs={12}>
              <Typography variant="body6" className={classes.applicationName}>
                {props?.departmentName || props?.formName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.applicationNumber}>
                {props?.applicationNumber}
                {/* "hi" */}
              </Typography>
            </Grid>
            {props?.formNo ? (
              <div style={{ marginTop: '10px', gap: '6px', display: 'flex', alignItems: 'center' }}>
                {/* <Typography style={{ color: '#505F79', fontSize: '14px' }}>Application Name:</Typography> */}
                <Typography variant="h5" style={{ color: '#505F79', fontSize: '14px', marginTop: '12px', fontWeight: 500 }}>
                  {props.formNo}
                </Typography>{' '}
              </div>
            ) : null}
          </Grid>
          {/* {props?.formNo ? (
            <div style={{ marginTop: '10px', display: 'flex' }}>
              <Typography style={{ color: '#505F79' }}>Application Name:</Typography>
              <Typography style={{ marginLeft: '5px' }}>{props.formNo}</Typography>{' '}
            </div>
          ) : null}
          {props?.formNo ? (
            <div style={{ marginTop: '10px', display: 'flex' }}>
              <Typography style={{ color: '#505F79' }}>Application Status:</Typography>
              <Typography style={{ marginLeft: '5px' }}>{props.Sub_Status__c}</Typography>{' '}
            </div>
          ) : null} */}
          <Grid item>
            {props?.dueDate && (
              <Typography fontSize={14} className={classes.red}>
                Due Date: {props.dueDate}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.bottomBox}>
        <Divider style={{ margin: '10px' }} />
        <CardActions
          sx={{
            padding: theme.spacing(2),
            alignItems: 'baseline',
            justifyContent: 'center',
          }}
        >
          <KenButton
            buttonClass={classes.btn}
            label={props?.dueFee?.status === 'Partial' || props?.dueFee?.status === 'Due' ? 'Pay Now' : props?.buttonTitle ? props?.buttonTitle : 'View Details'}
            variant="roundedButton"
            onClick={() => {
              props.handleViewDetails();
            }}
          />
        </CardActions>
      </div>
    </React.Fragment>
  );
}

export default function PaymentCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(TABLET_BREAKPOINT));

  const cardChanged = (
    <React.Fragment>
      <CardContent>
        <Grid container justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
          <Grid container item direction="row" justifyContent="space-between">
            <Grid item xs={12}>
              <Typography variant="body6" className={classes.applicationName}>
                {props?.departmentName || props?.formName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.applicationNumber}>
                {props?.applicationNumber}
                {/* "hi" */}
              </Typography>
            </Grid>
          </Grid>
          {props?.formNo ? (
            <div style={{ marginTop: '10px', gap: '6px', display: 'flex', alignItems: 'center' }}>
              {/* <Typography style={{ color: '#505F79', fontSize: '14px' }}>Application Name:</Typography> */}
              <Typography variant="h5" style={{ color: '#505F79', fontSize: '14px', marginTop: '12px', fontWeight: 500 }}>
                {props.formNo}
              </Typography>{' '}
            </div>
          ) : null}
          {/* {props?.formNo ? (
            <div style={{ marginTop: '10px', display: 'flex' }}>
              <Typography style={{ color: '#505F79' }}>Withdraw Status:</Typography>
              <Typography style={{ marginLeft: '5px' }}>{props.Sub_Status__c}</Typography>{' '}
            </div>
          ) : null} */}
          <Grid item>
            {props?.dueDate && (
              <Typography fontSize={14} className={classes.red}>
                Due Date: {props.dueDate}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.bottomBox}>
        <Divider style={{ margin: '10px' }} />
        <CardActions
          sx={{
            padding: theme.spacing(2),
            alignItems: 'baseline',
            justifyContent: 'center',
          }}
        >
          <KenButton
            buttonClass={classes.btn}
            label={props?.dueFee?.status === 'Partial' || props?.dueFee?.status === 'Due' ? 'Pay Now' : props?.buttonTitle ? props?.buttonTitle : 'View Details'}
            variant="roundedButton"
            onClick={() => {
              props.handleViewDetails();
            }}
          />
        </CardActions>
      </div>
    </React.Fragment>
  );

  return (
    <Card variant="outlined" className={classes.container} style={{ position: 'relative', display: 'grid' }}>
      {isMobileScreen ? <MobilePaymentCard {...props} /> : cardChanged}
    </Card>
  );
}
