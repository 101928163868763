import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import KenTextLabel from '../KenTextLabel';
import KenIcon from '../KenIcon';
import ErrorIcon from '../../Assets/Icons/error.png';
import KenTextField from '../KenTextField';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles({
  successMessage: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#158467',
  },
});

export default function KenInput(props) {
  const {
    label,
    required,
    optionalLabel,
    error,
    getValues,
    setValue,
    ui,
    name,
    success,
    initialItemValue,
    specialCase,
    disableKeyDown,
    hideLabel = false,
    helperText,
    minDate,
    ruleItemValue,
    educational,
    applicationState,
    ...otherProps
  } = props;
  const classes = useStyles();
  return (
    <>
      <Box width="100%">
        {!hideLabel && <KenTextLabel label={label} required={required} optionalLabel={optionalLabel} labelClassName={props.labelClassName} helperText={helperText} />}
        <Box display="flex" alignItems="flex-start">
          <KenTextField disableKeyDown={disableKeyDown} error={error} minDate={minDate} {...otherProps} label="" />
          {error && (
            <Box width="16px" height="16px" margin="19px 8px">
              <KenIcon iconType="img" icon={ErrorIcon} variant="extraSmall"></KenIcon>
            </Box>
          )}
        </Box>
        {success && (
          <Box display="flex" alignItems="flex-start" pt={1}>
            <Typography component="span">{success.icon || <DoneIcon fontSize="small" style={{ color: '#158467' }} />}</Typography>
            <Typography component="span" className={classes.successMessage} pl={1}>
              {success.message}
            </Typography>
          </Box>
        )}
      </Box>
      {ui && ui?.divider && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
    </>
  );
}
